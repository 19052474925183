import { ColorPalette } from '@komo-tech/core/models/ColorPalette';
import {
  CSSVariablesResolver,
  MantineProvider,
  MantineProviderProps,
  MantineThemeOverride
} from '@mantine/core';
import { FCC } from 'fcc';

import { resolveThemeForProvider } from './_MergedTheme';

export interface ThemeProviderProps
  extends Pick<
    MantineProviderProps,
    'defaultColorScheme' | 'forceColorScheme'
  > {
  theme?: MantineThemeOverride;
  colorPalettes?: ColorPalette[];
}

const fallbackTheme = resolveThemeForProvider();

export const cssResolver: CSSVariablesResolver = (theme) => {
  const c = theme.colors;
  const { black, white } = theme.other.colors;
  return {
    variables: {},
    light: {
      '--mantine-color-text': c.gray[9],
      '--color-body-border': c.gray[2],
      '--color-tooltip-bg': c.gray[9],
      '--color-tooltip-c': white,
      '--color-modal-bg': c.gray[2],
      '--color-success': c.green[8]
    },
    dark: {
      '--color-body-border': c.dark[3],
      '--color-tooltip-bg': c.gray[2],
      '--color-tooltip-c': black,
      '--color-modal-bg': c.dark[9],
      '--color-success': c.green[6]
    }
  };
};

export const ThemeProvider: FCC<ThemeProviderProps> = ({
  children,
  theme: themeProp,
  colorPalettes,
  defaultColorScheme = 'light',
  forceColorScheme
}) => {
  const theme = !!themeProp ? themeProp : fallbackTheme;

  if (colorPalettes) {
    theme.other.colors.palettes = colorPalettes;
  }

  if (!theme.fontFamily) {
    theme.fontFamily = fallbackTheme.fontFamily;
  }

  return (
    <MantineProvider
      defaultColorScheme={defaultColorScheme}
      theme={theme}
      forceColorScheme={forceColorScheme}
      cssVariablesResolver={cssResolver}
    >
      {children}
    </MantineProvider>
  );
};

interface OptionalThemeProviderProps extends ThemeProviderProps {
  withTheme?: boolean;
}
export const OptionalThemeProvider: FCC<OptionalThemeProviderProps> = ({
  withTheme,
  children,
  colorPalettes,
  forceColorScheme,
  theme
}) => {
  if (!withTheme) {
    return <>{children}</>;
  }

  return (
    <ThemeProvider
      colorPalettes={colorPalettes}
      theme={theme}
      forceColorScheme={forceColorScheme}
    >
      {children}
    </ThemeProvider>
  );
};
