import { ColorPalette } from '@komo-tech/core/models/ColorPalette';

export const SiteThemeDefaults = {
  bgColor: '#EFEFF4',
  accentBgColor: '#000000',
  accentColor: '#FFFFFF',
  headerBgColor: '#000000',
  headerColor: '#FFFFFF',
  footerBgColor: '#000000',
  footerColor: '#FFFFFF'
};

export interface ISiteThemeProperties {
  getBackgroundFilterColor: () => string | undefined;
  BackgroundColor?: string;

  HeaderBackgroundColor?: string;
  HeaderTextColor?: string;

  AccentBackgroundColor?: string;
  AccentTextColor?: string;

  FooterBackgroundColor?: string;
  FooterTextColor?: string;

  getColorPalettes: () => ColorPalette[];
}
